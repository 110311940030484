body.white{
    color: #333333;
    background-color: #FAFAFA;/*#FFFFFF; #f7f8f9; #f5f7f8;*/

    #topnav{
        background-color: #E8F5FF/*#4F9CF9*/;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }

    .logo-img{
        margin-top: 48px;
        margin-bottom: 70px;
    }

    .log-in-title{
        margin-top: 80px;
        font-size: 48px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .logo-text-outline, .logo-text, .logo-text:hover, .logo-text:active, .navbar-custom .topnav-menu .nav-link {
        color: #333333!important;
    }

    .logo-text-outline, .logo-text, .logo-text:hover, .logo-text:active {
        margin-bottom: 40px;
    }

    .navbar-custom .app-search .form-control {
        color: #333333;
        background-color: rgba(255, 255, 255, 1);
        border: none;
    }

    .page-title-box .page-title{
        color: #000000;
    }

    .btn-primary, .btn-black, .btn-outline-primary:hover{
        color: #fff;
        background-color:#4F9CF9;
        border-color:#4F9CF9;
    }

    .card-pricing{
        .btn-success{
            color: #fff;
            background-color:#4F9CF9;
            border-color:#4F9CF9;
        }
        .text-success{
            color:#4F9CF9!important;
        }
    } 

    ol.progtrckr li.progtrckr-done {
        color: #4F9CF9;
        border-bottom: 4px solid #4F9CF9;
    }
      
    ol.progtrckr li.progtrckr-done:before {
        background-color: #4F9CF9;
      }

    .text-primary{
        color:#4F9CF9!important;
    }

    .btn-outline-black, .btn-outline-primary{
        color:#4F9CF9;
        border-color:#4F9CF9;
    }

    .btn-success {
        color: #fff;
        background-color: #108F64;
        border-color: #108F64;
    }

    .form-control, .btn{
        border-radius: 5px;
    }

    .card{
        border: 1px solid #EFEFEF;
        border-radius: 15px;
        box-shadow: none;
    }

    .account-pages{
        .form-control{
            padding: 22px 13px;
            line-height: 20px;
        }
        .btn{
            padding: 13px 13px;
            line-height: 20px;
            box-shadow: none;
        }
        .card{
            box-shadow: unset;
            
            .p-4{
                padding: 0px!important;
            }

            .reg-desc{
                display: none;
            }
        }

        .divider-container{
            margin: 0 69px;
            padding: 20px 0;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;

            .withOr{
                background-color: #b9b9bd;
                height: 140px;
            }

            .divider{
                width: 1px;
                opacity: .8;
                background-color: #858f96;
            }
        }

        .social-login, .account-login{
            margin: auto;
        }

        .footer-link{
            color: #707070;
            text-decoration: underline;
        }
    }

    .spinner-loader > div {
        background-color:#4F9CF9;
    }

    .register-page{
        .withOr{
            background-color: #b9b9bd;
            height: 240px!important;
        }
    }

    .btn{
        box-shadow: none;
    }

    .btn:focus{
        outline: none!important;
    }

    .btn-border{
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .navbar-custom {
        background-color: #FFFFFF!important;
    }

    .nav-user img{
        height: 24px;
        width: 24px;
    }

    .btn-help {
        margin-top: 8px;
        i{
            font-size: 24px;
        }
    }
       
    .hdr-search-text{
        color:#4F9CF9;
        font-weight:300;
    }

    .domain-fillter{
        color:#bebebe;
        font-weight:100;
        font-size: 0.8rem;
    }

    .card-pricing.recommended{
        border: 2px solid#4F9CF9;
    }

    .signup-button{
        color: #4F9CF9;
        background-color: #fff;
        border-color: #4F9CF9;
    }

    .google-button{
        width: 100%!important;
        cursor: pointer!important;
        padding: 0!important;
        background-color: #4285f4!important;
        border: 1px solid transparent!important;
        color: #fff!important;
        margin: 10px 0!important;
        display: inline-flex!important;
        align-items: center!important;
        box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px!important;
        padding: 0px!important;
        border-radius: 5px!important;
        line-height: 20px;
        height: 48px;

        >div{
            width: 46px;
            height: 46px;
            border-radius: 5px!important;
        }

        >span{
            font-family: 'Mitr', 'Open Sans', 'Roboto', sans-serif!important;
            font-size: 0.875rem!important;
            font-weight: 300!important;
        }
    }

    .facebook-button{
        width: 100%!important;
        cursor: pointer!important;
        padding: 0!important;
        background-color: #3b5998!important;
        border: 1px solid transparent!important;
        color: #fff!important;
        margin: 10px 0!important;
        display: inline-flex!important;
        align-items: center!important;
        box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px!important;
        padding: 0px!important;
        border-radius: 5px!important;
        line-height: 20px;
        height: 48px;
        font-family: 'Mitr', 'Open Sans', 'Roboto', sans-serif;
        font-size: 0.875rem;
        font-weight: 300;

        i{
            color: #3b5998;
            margin-right: 10px;
            background: rgb(255, 255, 255);
            padding: 12px;
            border-radius: 5px;
            width: 46px;
            font-size: 22px;
        }
    }
}

body.authentication{
    background-color:#FFFFFF;
}